import DatePicker from 'react-datepicker';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { PopulatedDates, Report } from './reportTypes';
import { DateTime, Duration } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCheck, faClock, faFlag, faTableList, faUserNurse, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { AxiosContext } from '../../../contexts/AxiosContext';
import { AnnotateReportForm } from '../../admin/AlgorithmData';
import { PatientContext } from '../../../contexts/PatientContext';
import ReportStats from './ReportStats';
import Loading from '../../../components/Loading';
import { ErrorBox, WarningBox } from '../../../components/Errors';
import ReportGraph from './ReportGraph';
import SleepDetectionGraph from '../../admin/SleepDetectionGraph';
import Flag from '../../../components/FlagFA';


export function timestampToTime(timestamp: number): string {
    let date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    let minuteStr = minutes < 10 ? "0" + minutes : minutes;
    return hours + ':' + minuteStr + ampm;
}


export default function ReportSection(props: {report?: Report, testingNewScores?: boolean, populatedDates?: PopulatedDates | null}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramDate = searchParams.get("date");
    const initialDate = paramDate ? DateTime.fromISO(paramDate).toJSDate() : DateTime.now().startOf("day").toJSDate();
    const [date, setDate] = useState<Date>(initialDate);
    const [report, setReport] = useState<Report | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [scoresError, setScoresError] = useState<string | null>(null);
    const [warnings, setWarnings] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showTrainingBlock, setShowTrainingBlock] = useState<boolean>(false);
    const [showHrv, setShowHrv] = useState<boolean>(true);
    const [updatingSleep, setUpdatingSleep] = useState<boolean>(false);
    const [onset, setOnset] = useState<number | null>(null);
    const [finish, setFinish] = useState<number | null>(null);
    const { sherpahGet, sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const patient = useContext(PatientContext)!.patient!;

    const populatedDayClassName = (date: Date) => {
        const dateString = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
        if (!props.populatedDates) return "";
        if (props.populatedDates.full.includes(dateString)) {
            return "datepicker-populated-full";
        } else if (props.populatedDates.partial.includes(dateString)) {
            return "datepicker-populated-partial";
        } else {
            return "";
        }
    }

    const getReport = async () => {
        setError(null);
        setScoresError(null);
        setWarnings([]);
        setLoading(true);
        setReport(null);
        const dateString = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
        try {
            const response = await sherpahGet<Report>(`patients/${patient.user_id}/metrics?date=${dateString}`);
            if ("scores_error" in response.data) setScoresError(response.data.scores_error!);
            setWarnings(response.data.warnings || []);
            setReport(response.data);
            setShowTrainingBlock(response.data.needs_annotating || false);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    const updateTimes = (p1: number | null, p2: number | null) => {
        setOnset(p1);
        setFinish(p2);
    };

    const confirmSleep = async () => {
        setLoading(true);
        try {
            const response = await sherpahPost(`/patients/${patient.user.id}/doctor-assessments`, {
                date: DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"),
                sleep_onset: onset,
                sleep_finish: finish
            });
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            getReport();
            setUpdatingSleep(false);
            setOnset(null);
            setFinish(null);
        }
    };

    useEffect(() => {
        getReport();
    }, [date]);

    let content;
    if (report) {
        content = <>
            { report.stats && showTrainingBlock ? 
                <section className="rounded-container">
                    <AnnotateReportForm date={DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')} patientId={patient.user.id.toString()} onSkip={() => setShowTrainingBlock(false)} />
                </section> : null }

            <section id="reportContainer">
                {/* <h2>Heart Rate and Steps</h2> */}
                { updatingSleep ?
                    <SleepDetectionGraph report={report!} onClick={updateTimes} showVariation={false} useIndices={true} /> :
                    <ReportGraph report={report!} testingNewScores={props.testingNewScores} showHrv={showHrv} /> }
                <div className="graph-legend">
                    <div style={{ flex: 1 }} />
                    <div>
                        <div className="graph-legend-square" style={{ backgroundColor: "#7ACE4C" }}></div>
                        <div>
                            <label>Heart Rate</label>
                        </div>
                    </div>
                    <div>
                        <div className="graph-legend-square" style={{ backgroundColor: "#2A3238" }}></div>
                        <div>
                            <label>Steps</label>
                        </div>
                    </div>
                    <div>
                        <div className="graph-legend-square" style={{ backgroundColor: "#E4E4E4" }}></div>
                        <div>
                            <label>
                                Sleep
                                {/* { report?.sleep_assigned_by ? 
                                    <><br/><span style={{ color: "#aaa" }}>({report.sleep_assigned_by} assigned)</span></>
                                    : null } */}
                            </label>
                        </div>
                    </div>
                    { report.metrics.hrv ? 
                        <div>
                            <div className="graph-legend-square" style={{ backgroundColor: "#ed9631" }}></div>
                            <div>
                                <input type="checkbox" defaultChecked={showHrv} onChange={e => setShowHrv(e.target.checked)} />
                                <label>HRV HF</label>
                            </div>
                        </div>
                    : null }
                    <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                        { updatingSleep ? <>
                            <button onClick={confirmSleep} className="fa-icon-button" disabled={!onset || !finish}>
                                <span className="fa-wrapper"><FontAwesomeIcon icon={faCheck} /></span>
                                Confirm sleep periods
                            </button>
                            <button onClick={() => setUpdatingSleep(false)} className="secondary-button" style={{ marginLeft: 8 }}>
                                Cancel
                            </button>
                        </> :
                            <button onClick={() => setUpdatingSleep(true)} className="secondary-button fa-icon-button">
                                <span className="fa-wrapper"><FontAwesomeIcon icon={faClock} /></span>
                                Update sleep periods
                            </button>
                        }
                    </div>
                </div>
            </section>

            { report.stats ? <ReportStats report={report} /> : null }
        </>;
    } else {
        // content = <div>no report</div>;
    }


    return <div className="flex-col-gap-16">
        <div className="graph-controls-left">
            <b>Select date: </b>
            <button className="date-mover" onClick={() => setDate(d => new Date(d.getTime() - 86400000))}>
                <FontAwesomeIcon icon={faCaretLeft} />
            </button>
            <DatePicker
                className="report-date-picker"
                selected={date}
                onChange={(d: Date) => {
                    setDate(d);
                }}
                dateFormat="d MMM yyyy"
                dayClassName={populatedDayClassName} />
            <button className="date-mover" onClick={() => setDate(d => new Date(d.getTime() + 86400000))}>
                <FontAwesomeIcon icon={faCaretRight} />
            </button>

            <div style={{flex: 1}} />

            <b>Go to: </b>
            <button className="w-button pagination" onClick={() => setDate(DateTime.now().toJSDate())}>Today</button>
            <button className="w-button pagination" onClick={() => setDate(DateTime.now().minus({days: 1}).toJSDate())}>Yesterday</button>
            { patient.latest_report ? <button className="w-button pagination" onClick={() => setDate(DateTime.fromISO(patient.latest_report!).toJSDate())}>Last full report</button> : null }
        </div>

        <div style={{ display: "flex", gap: 8 }}>
            { report?.doctor_assessment ?
                <Flag
                    text="Doctor assigned sleep"
                    icon={faUserNurse}
                    tooltip={`A doctor has manually assigned the sleep period for this report.`}
                    color="#1e9fd1" /> : null }

            { report?.patient_assessment ?
                <Flag
                    text="Patient assessment"
                    icon={faTableList}
                    tooltip={`The patient has completed a report assessment for this date.`}
                    color="#e843b8" /> : null }
        </div>
        
        { (error || scoresError || warnings.length > 0) ? <div style={{display: "flex", gap: "8px", flexDirection: "column"}}>
                { scoresError ? <ErrorBox>{scoresError}</ErrorBox> : null }
                { error ? <ErrorBox>{error}</ErrorBox> : null }
                { warnings.map((warning, i) => <WarningBox key={i}>{warning}</WarningBox>) }
            </div> : null }

        { loading ? <Loading /> : null }

        { content }

    </div>;
}
