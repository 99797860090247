import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../contexts/AxiosContext';

import {
    SharedPatient as SharedPatientType,
    Doctor as DoctorType,
    PatientListItem
} from '../../types';

import "./doctor.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCheckCircle, faCircleQuestion, faClipboardUser, faCommentMedical, faComments, faDownload, faEdit, faExclamationCircle, faFolderMinus, faFolderPlus, faPrescriptionBottleMedical, faTable, faThumbTack, faTrash, faTrashCan, faUserLock, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { relativeDatetime } from '../../utils';
import Flag from '../../components/FlagImg';


type PatientProps = {
    patient: PatientListItem,
    onPin: (isPinned: boolean) => void,
    onArchive: (isArchived: boolean) => void
};

export function Patient(props: PatientProps) {
    const { patient } = props;

    return <div className="list-item my-patients-list-grid">
        <div onClick={() => props.onPin(!patient.is_pinned)} style={{ cursor: "pointer" }}>
            <span
                data-tooltip-id="pin-flag"
                data-tooltip-content={patient.is_pinned ? "Unpin patient" : "Pin patient"}>
                    <FontAwesomeIcon icon={faThumbTack} style={{color: patient.is_pinned ? "#0a8bbd" : "#d8d8d8"}} />
                    <Tooltip id="pin-flag" style={{zIndex: 1000}}/>
            </span>
        </div>
        <Link to={`/patients/${patient.user.id}`} style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <span style={{ fontSize: 17, marginRight: "8px" }}>{patient.user.last_name}, {patient.user.first_name}</span>
            <span className="patient-flags">
                { patient.is_undergoing_tms ? 
                    <Flag
                        text="Undergoing TMS"
                        icon="tms"
                        tooltip={`This patient is currently undergoing a course of TMS.`}
                        color="#70bfff" /> : null }
                { patient.has_completed_tms ? 
                    <Flag
                        text="Completed TMS"
                        icon="tick"
                        tooltip={`This patient has completed a course of TMS.`}
                        color="#4086f5" /> : null }
                { patient.flags?.includes("IMPROVED_TRENDS") ?
                    <Flag
                        text="Improved trends"
                        icon="tick"
                        tooltip={`This patient displays signs of positive progress during TMS treatment.`}
                        // tooltip={`${patient.user.first_name} displays signs of positive progress during TMS treatment.`}
                        // tooltip={`${patient.user.first_name}'s sleeping heart rate has dropped 34% since starting TMS.`}
                        color="#3da337" /> : null }
                { patient.flags?.includes("WORSENED_TRENDS") ?
                    <Flag
                        text="Worsened trends"
                        icon="alert"
                        tooltip={`This patient displays signs of regression during treatment.`}
                        color="#ff8f00" /> : null }
                { patient.flags?.includes("NO_CHANGE") ?
                    <Flag
                        text="No change"
                        icon="nil"
                        tooltip={`This patient displays no significant change in physiology during treatment.`}
                        color="#888" /> : null }
                { patient.flags?.includes("RELAPSE") ?
                    <Flag
                        text="Possible relapse"
                        icon="alert"
                        tooltip ={`This patient displays signs of a relapse post-treatment.`}
                        color="#d82e42" /> : null }
                { patient.flags?.includes("IMPROVED_SUBJECTIVE") ?
                    <Flag
                        text="Improved self-reports"
                        icon="happy"
                        tooltip ={`This patient has shown improvement from their questionnaire results.`}
                        color="#74c841" /> : null }
                { patient.flags?.includes("WORSENED_SUBJECTIVE") ?
                    <Flag
                        text="Worsened self-reports"
                        icon="sad"
                        tooltip ={`This patient has shown regresssion from their questionnaire results.`}
                        color="#e45345" /> : null }
                { patient.is_archived ? 
                    <Flag
                        text="Archived"
                        icon="archive"
                        tooltip={`This patient is archived.`}
                        color="#d82e42" /> : null }
                { patient.unacknowledged_comments > 0 ? 
                    <Flag
                        text={`${patient.unacknowledged_comments} comments`}
                        icon="comments"
                        tooltip={`There are ${patient.unacknowledged_comments} unacknowledged comments on this patient's profile.`}
                        color="#d82e9c" /> : null }
            </span>
        </Link>
        <Link to={`/patients/${patient.user.id}`}>
            { patient.latest_sync ?
                <span>{ relativeDatetime(patient.latest_sync) } ({ patient.smartwatch_type || "No watch connected" })</span>
                :
                <span><i className="empty-message">Hasn't synced.</i> { patient.smartwatch_type ? `(${patient.smartwatch_type})` : null }</span>
            }
        </Link>

        <Link to={`/patients/${patient.user.id}`}>
            { patient.latest_dass21 ? 
                <span>{ relativeDatetime(patient.latest_dass21) }</span>
                :
                <i className="empty-message">Hasn't completed DASS-21.</i>
            }
        </Link>

        <div className="list-actions">
            { patient.is_archived ? 
                <div onClick={() => props.onArchive(false)} className="list-action" data-tooltip-id="unarchive" data-tooltip-content="Unarchive">
                    <FontAwesomeIcon icon={faFolderPlus} className="list-action-icon" />
                    <Tooltip id="unarchive" style={{zIndex: 1000}}/>
                </div> : 
                <>
                <Link to={`/patients/${patient.user.id}/report`} className="list-action" data-tooltip-id="reportTooltip" data-tooltip-content="View report">
                    <FontAwesomeIcon icon={faChartLine} className="list-action-icon" />
                    <Tooltip id="reportTooltip" style={{zIndex: 1000}}/>
                </Link>
                <Link to={`/patients/${patient.user.id}/edit`} className="list-action" data-tooltip-id="edit" data-tooltip-content="Edit">
                    <FontAwesomeIcon icon={faEdit} className="list-action-icon" />
                    <Tooltip id="edit" style={{zIndex: 1000}}/>
                </Link>
                <div onClick={() => props.onArchive(true)} className="list-action" data-tooltip-id="archive" data-tooltip-content="Archive">
                    <FontAwesomeIcon icon={faFolderMinus} className="list-action-icon" />
                    <Tooltip id="archive" style={{zIndex: 1000}}/>
                </div>
                </>
            }
        </div>
    </div>;
}


type PatientsListProps = {
    patients: PatientListItem[],
    onUpdate: (patient: PatientListItem) => void
};
type Columns = "name" | "dass" | "sync";

export function PatientsList(props: PatientsListProps) {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<Columns>("name");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const { sherpahPut } = useContext(AxiosContext)!;

    const handleColumnClick = (column: Columns) => {
        if (column == sortColumn) {
            setSortOrder((sortOrder) => sortOrder == "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    }

    const pinPatient = (patient: PatientListItem, isPinned: boolean) => {
        sherpahPut(`/patients/${patient.user.id}`, {is_pinned: isPinned});
        const updatedPatient = {...patient, is_pinned: isPinned };
        props.onUpdate(updatedPatient);
    };

    const archivePatient = (patient: PatientListItem, isArchived: boolean) => {
        sherpahPut(`/patients/${patient.user.id}`, {is_archived: isArchived})
        const updatedPatient = {...patient, is_archived: isArchived };
        props.onUpdate(updatedPatient);
    };

    let sortedPatients: PatientListItem[] = [];
    if (props.patients) {
        sortedPatients = [...props.patients];
        if (sortColumn == "name") {
            sortedPatients.sort((a, b) => {
                if (a.is_pinned && !b.is_pinned) return -1;
                if (!a.is_pinned && b.is_pinned) return 1;
                const aValue = a.user.last_name;
                const bValue = b.user.last_name;
                return sortOrder == "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            });
        } else if (sortColumn == "dass") {
            sortedPatients.sort((a, b) => {
                const aValue = a.latest_dass21 ? DateTime.fromISO(a.latest_dass21).valueOf() : 0;
                const bValue = b.latest_dass21 ? DateTime.fromISO(b.latest_dass21).valueOf() : 0;
                return sortOrder == "asc" ? (bValue - aValue) : (aValue - bValue);
            });
        } else if (sortColumn == "sync") {
            sortedPatients.sort((a, b) => {
                const aValue = a.latest_sync ? DateTime.fromISO(a.latest_sync).valueOf() : 0;
                const bValue = b.latest_sync ? DateTime.fromISO(b.latest_sync).valueOf() : 0;
                return sortOrder == "asc" ? (bValue - aValue) : (aValue - bValue);
            });
        }
    }

    let sortChevron = (sortOrder == "asc") ? "▼" : "▲";

    return <div>
        { sortedPatients.length == 0 ? 
            <i className="empty-message">You currently have no patients.</i>
        :
            <div className="flex-col-gap-16">
                <div className="search-wrapper">
                    <img src="/search.png" />
                    <input type="text" placeholder="Search patients..." onChange={event => setSearchQuery(event.target.value)} />
                </div>
                <div className="list-container tall-list">
                    <div className="list-columns my-patients-list-grid">
                        <div className="list-column">
                            {/* <input type="checkbox" /> */}
                        </div>
                        <div className="list-column" onClick={() => handleColumnClick("name")}>
                            NAME { sortColumn == "name" ? sortChevron : null}
                        </div>
                        <div className="list-column" onClick={() => handleColumnClick("sync")}>
                            LATEST SYNC { sortColumn == "sync" ? sortChevron : null}
                        </div>
                        <div className="list-column" onClick={() => handleColumnClick("dass")}>
                            LATEST DASS-21 { sortColumn == "dass" ? sortChevron : null}
                        </div>
                        <div className="list-column">ACTIONS</div>
                    </div>
                        {sortedPatients
                            .filter(p => p.user.full_name.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map(patient => 
                                <Patient 
                                    patient={patient}
                                    key={patient.user.id}
                                    onPin={isPinned => pinPatient(patient, isPinned)}
                                    onArchive={isArchived => archivePatient(patient, isArchived)} />
                            )}
                </div>
            </div>
        }
    </div>;
}


export function TMSPatientsList(props: PatientsListProps) {
    const tmsPatients = props.patients.filter(p => p.is_undergoing_tms && !p.is_archived);
    if (tmsPatients.length == 0) {
        return <div>
            <i className="empty-message">You currently have no patients undergoing TMS.</i>
        </div>;
    }
    return <PatientsList patients={tmsPatients} onUpdate={props.onUpdate} />;
}


export function AllPatientsList(props: PatientsListProps) {
    const allPatients = props.patients.filter(p => !p.is_archived);
    if (allPatients.length == 0) {
        return <div>
            <i className="empty-message">You currently have no patients.</i>
        </div>;
    }
    return <PatientsList patients={allPatients} onUpdate={props.onUpdate} />;
}

export function ArchivedPatientsList(props: PatientsListProps) {
    const archivedPatients = props.patients.filter(p => p.is_archived);
    if (archivedPatients.length == 0) {
        return <div>
            <i className="empty-message">You currently have no archived patients.</i>
        </div>;
    }
    return <PatientsList patients={archivedPatients} onUpdate={props.onUpdate} />;
}


export function SharedPatient(props: {sharedPatient: SharedPatientType}) {
    const { sharedPatient } = props;

    return <div className="list-item shared-patients-list-grid">
        <Link to={`/patients/${sharedPatient.patient_id}`}>
            { sharedPatient.patient_name }
            { sharedPatient.deidentified ?
                <span
                    className="fa-wrapper"
                    data-tooltip-id="deidentified"
                    data-tooltip-content="Patient is deidentified."
                    style={{marginLeft: "8px"}}>
                    <FontAwesomeIcon icon={faUserLock} className="tooltip-icon" />
                    <Tooltip id="deidentified" style={{zIndex: 1000}}/>
                </span> : null }
        </Link>
        <Link to={`/patients/${sharedPatient.patient_id}`}>
            { sharedPatient.patient_doctor_name }
        </Link>
    </div>;
}

export function SharedPatientsList(props: {sharedPatients: SharedPatientType[]}) {
    const [searchQuery, setSearchQuery] = useState<string>("");
    
    return <div>
        { props.sharedPatients.length == 0 ? 
            <i className="empty-message">You currently have no shared patients.</i>
        :
            <div className="flex-col-gap-16">
                <div className="search-wrapper">
                    <img src="/search.png" />
                    <input type="text" placeholder="Search patients..." onChange={event => setSearchQuery(event.target.value)} />
                </div>
                <div className="list-container tall-list">
                    <div className="list-columns shared-patients-list-grid">
                        <div className="list-column">NAME</div>
                        <div className="list-column">DOCTOR</div>
                    </div>
                        {props.sharedPatients
                            .filter(p => p.patient_name.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map(p => <SharedPatient sharedPatient={p} key={p.patient_id} />)}
                </div>
            </div>
        }
    </div>;
}