import { PropsWithChildren } from "react";
import { Tooltip } from "react-tooltip";


type FlagProps = PropsWithChildren & {
    text: string,
    tooltip: string,
    color: string,
    icon: "tms" | "alert" | "tick" | "trend" | "archive" | "comments" | "nil" | "happy" | "sad"
};

export default function Flag(props: FlagProps) {
    // Image flag
    return <span
        className="flag"
        style={{ backgroundColor: props.color }}
        data-tooltip-id="flag-tooltip"
        data-tooltip-content={props.tooltip}>
        <img src={`/flags/${props.icon}.png`} />
        {props.text}
        <Tooltip id="flag-tooltip" style={{zIndex: 1000}}/>
    </span>
}
