import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip as TooltipChartJS,
    ChartData,
    BarElement,
    LineController,
    BarController,
    TimeScale,
    ChartOptions,
    ChartDataset,
    ChartTypeRegistry,
    Plugin,
    ChartEvent
} from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import { Chart, getDatasetAtEvent, Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Report } from '../patients/report/reportTypes';
import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { drawline, drawrect } from '../../utils';


ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Legend,
    BarElement,
    LineController,
    BarController,
    TooltipChartJS,
    zoomPlugin
);

type SleepDetectionGraphProps = {
    report: Report,
    onClick: (p1: number | null, p2: number | null) => void,
    showVariation?: boolean, // whether to show heart rate variation
    useIndices?: boolean // whether to return indices (0 - 1440) instead of timestamps (i.e 1714098485 )
};

export default function SleepDetectionGraph(props: SleepDetectionGraphProps) {
    const date = DateTime.fromISO(props.report.date);
    const timestamps = Array.from({length: 1440}, (_, i) => date.plus({minutes: i+720}));
    const chartRef = useRef<any>();

    let p1 = useRef<number | null>(null);
    let p2 = useRef<number | null>(null);

    const onChartClick = (e: ChartEvent, elements: any, chart: any) => {
        const canvasPosition = getRelativePosition(e, chart);

        // Substitute the appropriate scale IDs
        const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
        if (p1.current && p2.current) { p1.current = null; p2.current = null; }
        if (!(p1.current || p2.current)) {
            p1.current = dataX;
        } else if (p1.current) {
            if (dataX > p1.current) p2.current = dataX;
            else { p2.current = p1.current; p1.current = dataX; }
        }

        if (props.useIndices) {
            props.onClick(
                Math.round(chart.scales.x.getPixelForValue(p1.current)),
                Math.round(chart.scales.x.getPixelForValue(p2.current))
            )
        } else {
            props.onClick(p1.current, p2.current);
        }
    };

    const options: ChartOptions = {
        responsive: true,
        animation: false as const,
        plugins: {
            legend: false as const,
            tooltip: {
                enabled: true,
                intersect: false,
                mode: "index"
            },
            datalabels: {
                display: false
            },
        },
        scales: {
            x: {
                type: "time" as const,
                time: { displayFormats: { hour: "ha" } }
            },
            y: {
                min: 40,
                max: 180
            },
            y2: {
                min: -90,
                max: 30,
                beginAtZero: false,
                display: false
            }
        },
        onClick: onChartClick
    };

    let datasets: ChartDataset<"line" | "bar", number[] | {x: DateTime, y: number}[]>[] = [
        {
            type: "line" as const,
            label: "Heart rate",
            data: props.report.metrics.heart_rate,
            pointRadius: 0,
            pointBackgroundColor: "#7ace4c",
            fill: "#7ace4c",
            borderColor: "#7ace4c",
            borderJoinStyle: "round",
            borderWidth: 2.5
        }, {
            type: "bar" as const,
            label: "Steps",
            data: props.report.metrics.steps.map(v => 40+v/5),
            backgroundColor: "#2A3238",
            barThickness: 1
        }
    ];

    if (props.report.metrics.variation && props.showVariation) {
        datasets.push({
            type: "bar" as const,
            label: "Variation",
            data: props.report.metrics.variation,//.map(v => v/4),
            backgroundColor: "#ff0000",
            barThickness: 1,
            yAxisID: "y2"
        })
    }

    const data: ChartData<"line" | "bar", number[] | {x: DateTime, y: number}[], DateTime> = {
        labels: timestamps,
        datasets: datasets,
    };

    const mouseLinePlugin: Plugin = {
        id: "mouseLine",
        afterDraw: (chart) => {
            let hoverX;
            if (chart.tooltip?.dataPoints?.length) {
                hoverX = chart.tooltip.dataPoints[0].parsed.x;
            } else { return; }
            if (p1.current) {
                drawline(chart, p1.current);
                if (p2.current) drawrect(chart, p1.current, p2.current);
                else drawrect(chart, p1.current, hoverX);
            }
            if (p2.current) drawline(chart, p2.current);
            drawline(chart, hoverX);
        }
    }

    return <Chart
        type="line"
        data={data}
        options={options}
        // onClick={onChartClick}
        style={{ cursor: "pointer" }}
        plugins={[mouseLinePlugin]} />;
}

