import { faCircleQuestion, faForward, faQuestion, faTable, faUpload, faUserPlus, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { Report } from '../patients/report/reportTypes';
import Loading from '../../components/Loading';
import { ErrorText } from '../../components/Errors';
import SleepDetectionGraph from './SleepDetectionGraph';

type RandomReport = Report & {
    patientId: string,
    date: string,
    smartwatch: string,
    total_annotated: number
};

type DatasetFormValues = {
    sleep_onset: string,
    onset_confidence: number,
    sleep_finish: string,
    finish_confidence: number,
    comment: string
};

type Dataset = DatasetFormValues & {
    date: string,
    patientId: string,
    smartwatch: string,
};

const CONFIDENCE = ['Uncertain', 'Somewhat confident', 'Fairly confident', 'Certain'];
// const SLEEP_HEALTH = ['Extremely unhealthy', 'Unhealthy', 'Somewhat healthy', 'Fairly healthy', 'Healthy'];

export function timestampToTime(timestamp: number): string {
    let date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    let minuteStr = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minuteStr + ampm;
}

export default function SleepDetection() {
    const { sherpahGet, sherpahPost, catchAxios } = useContext(AxiosContext)!;
    const [error, setError] = useState<string | null>(null);
    const [date, setDate] = useState<string>("");
    const [patientId, setPatientId] = useState<string>("");
    const [report, setReport] = useState<Report | null>(null);
    const [showVariation, setShowVariation] = useState<boolean>(true);

    const [onsetConfidence, setOnsetConfidence] = useState<number>(3);
    const [finishConfidence, setFinishConfidence] = useState<number>(3);
    const [sleepHealth, setSleepHealth] = useState<number | null>(null);
    let onsetRef = useRef<HTMLInputElement | null>(null);
    let finishRef = useRef<HTMLInputElement | null>(null);
    const [comment, setComment] = useState<string>("");

    const [totalAnnotated, setTotalAnnotated] = useState<number>(0);

    const resetForm = () => {
        if (onsetRef.current) onsetRef.current.value = "";
        if (finishRef.current) finishRef.current.value = "";
        setOnsetConfidence(3);
        setFinishConfidence(3);
        setSleepHealth(null);
        setComment("");
    };

    const skip = () => {
        setError(null);
        resetForm();
        getRandomReport();
    }

    const onSubmit = async (reason?: string) => {
        setError(null);
        let data: any = {
            date: date,
            patient_id: patientId
        };
        if (reason) {
            data["comment"] = "#SLEEP_NORMALISATION #" + reason;
        } else {
            data["sleep_onset"] = onsetRef.current?.value;
            data["sleep_finish"] = finishRef.current?.value;
            data["comment"] = `#SLEEP_NORMALISATION #ONSET_CONF=${onsetConfidence} #FINISH_CONF=${finishConfidence} #SLEEP_HEALTH=${sleepHealth} ${comment}`;
        }

        try {
            const response = await sherpahPost(`/patients/annotated-reports`, data);
            resetForm();
            getRandomReport();
        } catch (e: any) {
            catchAxios(e, setError);
        }
    };

    const getRandomReport = async () => {
        setReport(null);
        setError(null);
        try {
            const response = await sherpahGet<RandomReport>("patients/random?sleep-detection=true");
            if (response.data.scores_error) {
                // if there's a scores error, fetch another report
                await getRandomReport();
                return;
            } else {
                setReport(response.data);
                setDate(response.data.date);
                setPatientId(response.data.patientId);
                setTotalAnnotated(response.data.total_annotated);
            }
        } catch (e: any) {
            catchAxios(e, setError);
        }
    };

    useEffect(() => {
        getRandomReport();
    }, []);

    const updateTimes = (p1: number | null, p2: number | null) => {
        if(onsetRef.current && p1) onsetRef.current.value = timestampToTime(p1);
        if(finishRef.current && p2) finishRef.current.value = timestampToTime(p2);
    }

    return <main>
        <div className="heading-wrapper">
            <h1>Sleep Detection Trainer</h1>
			<div className="heading-wrapper-actions">
                <h3>Sleep datasets completed: { totalAnnotated - 93 }</h3>
            </div>
        </div>

        <div className="rounded-container">
            {/* <div>
                This page is for obtaining datasets to train the sleep detection algorithm.
                Random 24-hour deidentified patient records are graphed below.
                Fill in the fields below the graph for each patient and press Submit.
                You also have the option to skip on any patient.
            </div> */}
            { error ? <ErrorText>{error}</ErrorText> : null }
            { report && !error ? <>
            
            <div style={{ display: "flex", gap: "16px" }}>
                <div style={{ flex: 1}}>
                    <b style={{ display: "block", textAlign: "center", marginRight: 8, fontSize: 20 }}>Sleep onset:</b>
                    <input name="sleep_onset" type="text" style={{ textAlign: "center", fontSize: 18, margin: "8px 0" }} ref={onsetRef} />
                    <div style={{ display: "flex", gap: "12px", justifyContent: "center" }}>
                        <b style={{ flex: 1, textAlign: "center" }}>Confidence:</b>
                        <input style={{ flex: 1, textAlign: "center" }} name="confidence" type="range" min="0" max="3" defaultValue={onsetConfidence} onChange={e => setOnsetConfidence(parseInt(e.target.value))} />
                        <span style={{ flex: 1, textAlign: "center" }}>{CONFIDENCE[onsetConfidence]}</span>
                    </div>
                </div>
                <div style={{ flex: 1}}>
                    <b style={{ display: "block", textAlign: "center", marginRight: 8, fontSize: 20 }}>Sleep finish:</b>
                    <input name="sleep_finish" type="text" style={{ textAlign: "center", fontSize: 18, margin: "8px 0" }} ref={finishRef} />
                    <div style={{ display: "flex", gap: "12px", justifyContent: "center" }}>
                        <b style={{ flex: 1, textAlign: "center" }}>Confidence:</b>
                        <input style={{ flex: 1, textAlign: "center" }} name="confidence" type="range" min="0" max="3" defaultValue={finishConfidence} onChange={e => setFinishConfidence(parseInt(e.target.value))} />
                        <span style={{ flex: 1, textAlign: "center" }}>{CONFIDENCE[finishConfidence]}</span>
                    </div>
                </div>
                <div style={{ flex: 1}}>
                    <b style={{ display: "block", textAlign: "center", marginRight: 8, fontSize: 20 }}>Sleep health score:</b>
                    <div style={{ flex: 1, textAlign: "center", fontSize: 32, marginTop: "16px" }}>{sleepHealth ?? <i>Not set</i>}</div>
                    <div style={{ display: "flex", gap: "12px", justifyContent: "center", marginTop: "16px" }}>
                        <b style={{ flex: 1, textAlign: "center" }}>0: unhealthy</b>
                        <input style={{ flex: 1, textAlign: "center" }} name="confidence" type="range" min="0" max="10" defaultValue={5} onChange={e => setSleepHealth(parseInt(e.target.value))} />
                        <b style={{ flex: 1, textAlign: "center" }}>10: healthy</b>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <b style={{ display: "block", textAlign: "center", marginRight: 8, fontSize: 20 }}>Other comments (optional):</b>
                    <input name="comment" type="text" style={{ margin: "8px 0" }} onChange={e => setComment(e.target.value)} />
                </div>
            </div>

            <hr style={{ margin: "24px 0"}}/>

            <SleepDetectionGraph report={report!} onClick={updateTimes} showVariation={showVariation} />
    
            <div className="graph-legend">
                <div>
                    <div className="graph-legend-square" style={{ backgroundColor: "#7ACE4C" }}></div>
                    <div>Heart Rate</div>
                </div>
                <div>
                    <div className="graph-legend-square" style={{ backgroundColor: "#2A3238" }}></div>
                    <div>Steps</div>
                </div>
                <div>
                    <div className="graph-legend-square" style={{ backgroundColor: "#FF0000" }}></div>
                    <div>
                        <input type="checkbox" defaultChecked={showVariation} onChange={e => setShowVariation(e.target.checked)} />
                        Variation
                    </div>
                </div>
            </div>

            <hr style={{ margin: "24px 0"}}/>

            <div style={{ display: "grid", gap: "16px", gridTemplateColumns: "1fr 1fr", alignItems: "center" }}>
                <button type="submit" className="w-button" style={{ flex: 1, fontSize: 20, padding: 16}} onClick={() => onSubmit()}>
                    <FontAwesomeIcon icon={faUpload} style={{marginRight: 16}} />
                    Submit
                </button>
                <button type="button" className="w-button secondary-button" style={{ flex: 1, fontSize: 20, padding: 16}} onClick={skip}>
                    <FontAwesomeIcon icon={faForward} style={{marginRight: 16}} />
                    Skip
                </button>
                <button type="button" className="w-button secondary-button" style={{ flex: 1, fontSize: 20, padding: 16}} onClick={() => onSubmit("POOR_QUALITY")}>
                    <FontAwesomeIcon icon={faUserSlash} style={{marginRight: 16}} />
                    Poor data quality
                </button>
                <button type="button" className="w-button secondary-button" style={{ flex: 1, fontSize: 20, padding: 16}} onClick={() => onSubmit("NO_SLEEP_PERIOD")}>
                    <FontAwesomeIcon icon={faQuestion} style={{marginRight: 16}} />
                    Good data quality but no discernable sleep period
                </button>
            </div>
            </> : <Loading /> }

            </div>
    </main>

}