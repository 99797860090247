import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";
import { Tooltip } from "react-tooltip";


type FlagProps = PropsWithChildren & {
    text: string,
    tooltip: string,
    color: string,
    icon: IconDefinition
};

export default function Flag(props: FlagProps) {
    // FontAwesome flag
    return <span
        className="flag"
        style={{ backgroundColor: props.color }}
        data-tooltip-id="flag-tooltip"
        data-tooltip-content={props.tooltip}>
        <span className="fa-wrapper">
            <FontAwesomeIcon icon={props.icon} />
        </span>
        {props.text}
        <Tooltip id="flag-tooltip" style={{zIndex: 1000}}/>
    </span>
}
