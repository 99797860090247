import { AxiosResponse } from "axios";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { Slide, ToastOptions } from "react-toastify";

export function decodeJwt(token: string): any | null {
	if (token.split(".").length !== 3 || typeof token !== "string") {
		return null;
	}

	let payload = token.split(".")[1];
	let padding = "=".repeat((4 - payload.length % 4) % 4);
	let base64 = payload.replace("-", "+").replace("_", "/") + padding;
	let decoded = JSON.parse(atob(base64));
	return decoded;
}

export function scoreDesc(score: number): string {
	if(score < 1) return "Normal";
	else if(score < 3) return "Very Mild";
	else if(score < 5) return "Mild";
	else if(score < 7) return "Moderate";
	else return "Severe";
}

const DATETIME_FORMAT: DateTimeFormatOptions = {
	...DateTime.DATETIME_MED,
	hourCycle: "h12"
};

export function relativeDatetime(datetime: string) {
	return DateTime.fromISO(datetime, {zone: 'utc'}).toLocal().toRelative();
}

export function formatDatetime(datetime: string) {
	return DateTime.fromISO(datetime, {zone: 'utc'}).toLocal().toLocaleString(DATETIME_FORMAT);
}

export function formatDate(date: Date | string) {
	if (typeof date === "string") {
		return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
	} else {
		return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED);
	}
}


export async function downloadRequest(request: Promise<AxiosResponse>, filename: string, filetype: string) {
	const response = await request;
	const data = new Blob([response.data], {"type": filetype});
	const anchor = document.createElement("a");
	anchor.setAttribute("href", window.URL.createObjectURL(data));
	anchor.setAttribute("download", filename);
	anchor.setAttribute("filename", filename);
	anchor.click();
}


export const DEFAULT_TOAST_SETTINGS: ToastOptions = {
	position: "bottom-center",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	progress: undefined,
	theme: "light",
	transition: Slide
};

export async function logError(message?: any, ...optionalParams: any[]) {
	console.error(message, optionalParams);
}

export async function logInfo(message?: any, ...optionalParams: any[]) {
	console.log(message, optionalParams);
}


export function drawline(chart: any, val: number) {
	let yAxis = chart.scales.y;
	let xAxis = chart.scales.x;
	let ctx = chart.ctx;
	ctx.save();
	ctx.beginPath();
	let x = xAxis.getPixelForValue(val);
	ctx.moveTo(x, yAxis.top);
	ctx.lineTo(x, yAxis.bottom);
	ctx.lineWidth = 2;
	ctx.strokeStyle = 'rgba(0, 0, 255)';
	ctx.stroke();
	ctx.restore(); 
}

export function drawrect(chart: any, x1: number, x2: number) {
	let yAxis = chart.scales.y;
	let xAxis = chart.scales.x;
	let ctx = chart.ctx;
	const x1Pixel = xAxis.getPixelForValue(x1);
	const x2Pixel = xAxis.getPixelForValue(x2);
	const width = Math.abs(x2Pixel - x1Pixel);
	ctx.fillStyle = "rgba(0, 0, 255, 0.1)";
	ctx.fillRect(
		Math.min(x1Pixel, x2Pixel), // x position (left edge)
		yAxis.top,                  // y position (top edge)
		width,                      // width
		yAxis.height                // height (full y-axis height)
	);
}